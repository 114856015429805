<template>
<div id="joinCompany">
  <div class="step-box">
    <h3>开启高效的招聘方式，你只需要 3步：</h3>
    <div class="steps">
      <div class="step-one">
        <p>
          <span>1</span>
          <label>创建名片</label>
        </p>
      </div>
      <div class="line"></div>
      <div class="step-one">
        <p>
          <span>2</span>
          <label>加入公司</label>
        </p>
      </div>
      <div class="line lineGray"></div>
      <div class="step-three">
        <p>
          <span>3</span>
          <label>发布第一个职位</label>
        </p>
      </div>
    </div>
  </div>
  <div class="companyInfo">
     <h3>加入公司</h3>
     <div class="company-ct">
         <p>您将加入：{{companyInfo.enterpriseName}}</p>
         <p>并以如下信息向牛人展示:</p>
         <div class="info">
             <img :src="companyInfo.enterpriseLogo" alt="">
             <span>{{companyInfo.enterpriseName}} | {{companyInfo.industryName}}</span>
         </div>
     </div>
     <div class="attestion">
      <el-upload class="license-uploader" :on-success="licenseSuccess" :action="baseUrl" :show-file-list="false">
        <img v-if="businessUrl" :src="businessUrl" class="license-pic">
        <div class="license-btn" v-else>
          <img src="@/assets/imgs/company/upload-btn.png" />
          <span>上传营业执照/组织机构代码证</span>
        </div>
      </el-upload>
    </div>
    <div class="btn-box">
       <router-link :to="{path:'/addContacts'}"><div class="cancel-btn">上一步</div></router-link>
       <div class="submit-btn" @click="submit">确认加入</div>
    </div>
  </div>
</div>
</template>

<script>
import { getEnterpriseInfo,addIndividualQualification } from "@/api/index.js";
export default {
  name: "joinCompany",
  data() {
    return {
     companyInfo:{},
     baseUrl:'',
     businessUrl:''
    }
  },
  mounted() {
      this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
    this.getEnterpriseInfo()
  },
  methods: {
    licenseSuccess(res) {
      this.businessUrl = res.url
    },
   getEnterpriseInfo(){
     getEnterpriseInfo({
        enterpriseName:this.$route.query.enterpriseName
     }).then(res=>{
      this.companyInfo = res.data
     })
   },
   submit(){
    if(this.businessUrl){
       addIndividualQualification({
         businessUrl:this.businessUrl,
         enterpriseId:this.companyInfo.id
       }).then(res=>{
        if(res.code==200){
            this.$message({
              message: '加入企业成功',
              type: 'success'
            })
          this.$router.push({
            path: '/verify'
          })

        }else{
            this.$message({
              message: res.msg,
              type: 'error'
            })
        }
         console.log(res)
       })
    }else{
       this.$message({
          message: '请上传营业执照',
          type: 'warning'
        })
    }
   }
  }
};
</script>

<style lang="scss" scoped>
#joinCompany {
  width: 100%;
  min-height: 814px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  padding-bottom: 30px;
  
}

.step-box {
  padding-top: 31px;
  width: 1200px;
  margin: 0 auto;

  h3 {
    font-size: 26px;
    font-weight: 400;
    color: #2A2C33;
    float: left;
    margin-top: 40px;
  }

}

.steps {
  float: right;
  height: 100px;

  .step-one {
    float: left;
    text-align: center;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #0077FF;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #0077FF;
        margin-top: 10px;
      }
    }
  }

  .line {
    width: 119px;
    height: 2px;
    background: #0077FF;
    border-radius: 1px;
    float: left;
    margin-top: 21px;
  }

  .lineGray {
    background: #999999;
  }

  .step-two {
    float: left;
    margin-left: 8px;
    text-align: center;
    cursor: pointer;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #0077FF;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
      }
    }
  }

  .step-three {
    float: left;
    margin-left: 8px;
    cursor: pointer;

    p {
      float: left;

      span {
        display: inline-block;
        width: 41px;
        height: 41px;
        background: #999999;
        border-radius: 50%;
        text-align: center;
        line-height: 41px;
        color: #fff;
        font-size: 18px;
      }

      label {
        display: block;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
        position: relative;
        right: 70px;
      }
    }
  }
}
.companyInfo{
  width: 1065px;
min-height: 473px;
margin: 31px auto;
background: #FFFFFF;
padding-top:40px;
border-radius: 1px 10px 10px 10px;
overflow: hidden;
padding-bottom: 40px;
h3{
  font-size: 24px;
font-weight: bold;
color: #222222;
text-align: center;
margin-bottom: 30px;
}
.company-ct{
  width:730px;
  height: 254px;
  background: #F5F5F5;
  border-radius: 5px;
  margin:0 auto;
  padding:5px 20px;
  p{
    font-size: 16px;
  color: #222222;
  line-height: 50px;
  }
  .info{
    width: 732px;
height: 140px;
background: #FFFFFF;
border-radius: 5px;
img{
  width: 86px;
  height: 86px;
  margin: 29px 40px;
  border-radius: 50%;
  float: left;
}
span{
  display: block;
  float: left;
  margin-top: 60px;
  font-size: 16px;
color: #222222;
}
  }
}
}
 .attestion {
    text-align: center;

    .license-btn {
      width: 770px;
      height: 173px;
      background: #EAF4FF;
      border-radius: 5px;
      margin-top: 10px;

      img {
        width: 40px;
        height: 40px;
        margin-top: 50px;
        margin-bottom: 13px;
      }

      span {
        display: block;
        font-size: 16px;
        color: #999999;
      }
    }

    .license-pic {
       width: 770px;
      height: 173px;
    }
  }
.btn-box{margin-top: 30px;
  .cancel-btn{
    width: 218px;
height: 50px;
line-height: 50px;
text-align: center;
background: #EAF4FF;
border-radius: 10px;
font-size: 16px;
font-weight: 400;
color: #0077FF;
float: left;
cursor: pointer;
margin-left: 270px;
  }
   .submit-btn{
    width: 218px;
height: 50px;
line-height: 50px;
text-align: center;
background: #0077FF;
border-radius: 10px;
font-size: 16px;
font-weight: 400;
color: #fff;
float: left;
margin-left: 42px;
cursor: pointer;
  }
}
</style>
